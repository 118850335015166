import ApiService from "./api.service";
import router from "@/router";

const DrawingsService = {
  async getBackgrounds(backgrondType) {
    return await ApiService.commonGETRequest(
      `/drawings/background/`,
      backgrondType ? { type: backgrondType } : {}
    );
  },
  async downloadPicture(url, owner) {
    return await ApiService.commonGETRequest(url, { owner }, "arraybuffer");
  },
  async getDrawing(drawing, owner) {
    return await ApiService.commonGETRequest(`/drawings/${drawing.id}/`, { owner });
  },
};

export default DrawingsService;
