<template>
  <div>
    <v-lazy @click.stop="handleClick" v-model="intersected">
      <SubviewCard class="card-style" :customShadow="{}">
        <IconButton
          v-if="deletable"
          icon="mdi-trash-can-outline"
          @clicked="showDeletePopup = true"
          color="white"
          bgcolor="error"
          fontSize="16px"
          noYMargin
          noXMargin
          class="mx-1 delete"
        ></IconButton>
        <v-layout column>
          <v-layout column align-center>
            <v-layout
              v-if="loading"
              justify-center
              column
              class="mt-6"
              style="min-height: 114px; position: absolute; z-index: 2"
            >
              <v-flex shrink>
                <v-progress-circular color="primary" :size="50" indeterminate>
                </v-progress-circular>
              </v-flex>
            </v-layout>
            <v-layout align-start shrink>
              <v-icon small class="mr-2" color="primary">mdi-pencil</v-icon>
              <v-layout shrink :class="!single && 'mr-7'">
                <DefaultLabel>Edytuj ilustrację</DefaultLabel>
              </v-layout>
            </v-layout>
            <v-layout style="height: 114px" align-center justify-center>
              <v-img
                :src="miniature"
                min-width="168"
                max-width="168"
                contain
              ></v-img>
            </v-layout>
            <v-layout shrink class="max-width" align-center>
              <v-spacer></v-spacer>
              <DefaultSubText class="font-weight-bold" ellipsis>{{
                title
              }}</DefaultSubText>
              <v-spacer></v-spacer>
              <IconButton
                icon="mdi-history"
                @clicked="showHistoryPopup = true"
                bgcolor="transparent"
                noYMargin
                noXMargin
                class="mx-1"
              ></IconButton>
            </v-layout>
          </v-layout>
        </v-layout>
      </SubviewCard>
    </v-lazy>
    <EditorPopup
      ref="editor"
      v-if="showEditorPopup"
      :value.sync="showEditorPopup"
      :data="data"
      :mode="editorMode"
      :title="`Edytuj - ${name}`"
      @save="onSave"
      edit
    ></EditorPopup>
    <ImageViewerDialog
      v-if="showImagePreview"
      :value.sync="showImagePreview"
      :file="viewerFile"
    >
    </ImageViewerDialog>
    <DrawingHistoryFieldDialog
      v-if="showHistoryPopup"
      :value.sync="showHistoryPopup"
      :record="value"
      :session="session"
      :patient="patient"
      :owner="owner"
      :name="name"
    >
    </DrawingHistoryFieldDialog>
    <ConfirmDialog
      title="Usuń ilustrację"
      text="Wybrana ilustracja zostanie usunięta bezpowrotnie."
      v-if="showDeletePopup"
      :value.sync="showDeletePopup"
      :positiveAction="onRemove"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import DrawingsService from "@/services/drawings.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    value: {},
    session: {},
    patient: {},
    owner: {},
    deletable: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    editorMode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDeletePopup: false,
      showEditorPopup: false,
      showImagePreview: false,
      showHistoryPopup: false,
      data: null,
      title: this.value.title,
      fileSrc: this.value.file,
      miniatureSrc: this.value.miniature,
      miniature: "",
      viewerFile: null,
      intersected: false,
    };
  },
  components: {
    IconButton: () => import("@/components/buttons/IconButton"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    EditorPopup: () => import("@/components/popups/drawings/EditorPopup"),
    ImageViewerDialog: () => import("@/views/ImageViewerDialog"),
    DrawingHistoryFieldDialog: () =>
      import("@/components/popups/patientcard/DrawingHistoryFieldDialog"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
  watch: {
    value: {
      handler() {
        if (this.value.miniature != this.miniatureSrc) {
          this.miniatureSrc = this.value.miniature;
          if (this.intersected) {
            this.downloadMiniature();
          }
        }
      },
      deep: true,
    },
    showEditorPopup() {
      this.$emit("editor", this.showEditorPopup);
    },
  },
  methods: {
    async fetch() {
      return new Promise((resolve, reject) => {
        this.beforeRequest();
        DrawingsService.getDrawing(this.value, this.owner ? this.owner.id : undefined)
          .then((reply) => {
            this.data = reply.data;
            this.title = reply.title;
            this.fileSrc = reply.file;
            this.miniatureSrc = reply.miniature;
            this.reset();
            console.log("this.miniatureSrc", reply);
            resolve();
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            this.reset();
            reject();
          });
      });
    },
    async downloadPicture(url) {
      return new Promise((resolve, reject) => {
        this.beforeRequest();
        DrawingsService.downloadPicture(
          process.env.VUE_APP_URL_BACKEND + "/media/" + url,
          this.owner ? this.owner.id : undefined
        )
          .then((reply) => {
            const blob = new Blob([reply], {
              type: "image/jpeg",
            });
            this.reset();
            resolve(URL.createObjectURL(blob));
          })
          .catch((error) => {
            this.reset();
            reject(error);
          });
      });
    },
    handleClick() {
      this.fetch().then(() => {
        if (this.owner) {
          this.downloadPicture(this.fileSrc).then((file) => {
            this.viewerFile = { type: 0, image: file };
            this.showImagePreview = true;
          });
        } else {
          this.showEditorPopup = true;
        }
      });
    },
    onSave(data) {
      this.$emit("edit", { ...data, title: this.title, id: this.value.id });
    },
    onRemove() {
      this.$emit("remove", { title: this.title, id: this.value.id });
    },
    downloadMiniature() {
      this.downloadPicture(this.miniatureSrc).then(
        (miniature) => (this.miniature = miniature)
      );
    },
  },
  mounted() {
    this.downloadMiniature();
  },
};
</script>

<style scoped lang="scss">
.max-width {
  width: 168px !important;
}
.card-style {
  border-radius: 0.5rem !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--v-border-base) !important;
  background-color: white !important;
  width: 200px !important;
  height: 190px !important;
  cursor: pointer;
}
.miniature {
  height: 158px !important;
}
.single-placeholder {
  height: 30px !important;
}
.delete {
  position: absolute !important;
  top: 16 !important;
  right: 16px !important;
  z-index: 1;
}
</style>